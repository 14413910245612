<template lang="html">
  <div id="page-user-edit">
    <vx-card
      v-if="this.data_local"
      :class="data_local.id ? 'w-full' : 'w-1/2'"
      :title="$t('common.registerData')"
    >
      <div class="vx-row">
        <div class="vx-col" :class="data_local.id ? 'w-1/2' : 'w-full'">
          <vs-input
            class="w-full mt-4"
            label="Id"
            v-model="data_local.id"
            name="id"
            v-if="this.data_local.id"
            disabled
          />

          <label class="vs-input--label">{{ $t("machine.company") }}</label>
          <v-select
            v-model="data_local.company_id"
            :clearable="false"
            :options="companyOptions"
            v-validate="'required'"
            name="current_company"
            :searchable="true"
            :reduce="x => x.id"
            label="name"
            :data-vv-as="$t('machine.company')"
          >
            <span slot="no-options">{{ $t("navbar.noResults") }}</span>
          </v-select>
          <span
            class="text-danger text-sm"
            v-show="errors.has('current_company')"
            >{{ errors.first("current_company") }}</span
          >

          <vs-input
            class="w-full mt-4"
            label="Email"
            v-model="data_local.login"
            type="email"
            v-validate="'required|email'"
            name="email"
            :disabled="this.data_local.id"
          />
          <span class="text-danger text-sm" v-show="errors.has('email')">{{
            errors.first("email")
          }}</span>

          <vs-input
            class="w-full mt-4"
            :label="$t('common.name')"
            v-model="data_local.name"
            v-validate="'required'"
            name="name"
            :data-vv-as="$t('common.name')"
          />
          <span class="text-danger text-sm" v-show="errors.has('name')">{{
            errors.first("name")
          }}</span>

          <div class="mt-4">
            <label class="vs-input--label">{{ $t("user.permission") }}</label>
            <v-select
              v-if="data_local.type !== 2"
              v-model="data_local.type"
              :clearable="false"
              :options="roleOptions"
              v-validate="'required'"
              name="role"
              :reduce="x => x.value"
              :data-vv-as="$t('user.permission')"
              :searchable="true"
              :disabled="
                $store.state.AppActiveUser.type === 0 ||
                  $store.state.AppActiveUser.id == data_local.id
              "
            >
              <span slot="no-options">{{ $t("navbar.noResults") }}</span>
            </v-select>
            <span class="text-danger text-sm" v-show="errors.has('role')">{{
              errors.first("role")
            }}</span>

            <span v-if="data_local.type === 2">: {{ $t("permission.root") }}</span>
          </div>
        </div>

        <div class="vx-col w-1/3 ml-auto" v-if="this.data_local.id">
          <vs-alert color="warning" icon-pack="feather" icon="icon-info">
            <span>{{ $t("user.messages.info.passwordChange") }}</span>
          </vs-alert>

          <vs-input
            class="w-full mt-4"
            :label="$t('login.password')"
            v-model="password"
            ref="password"
            type="password"
            name="password"
            :data-vv-as="$t('login.password')"
          />
          <span class="text-danger text-sm" v-show="errors.has('password')">{{
            errors.first("password")
          }}</span>

          <vs-input
            class="w-full mt-4"
            :label="$t('login.passwordConfirm')"
            v-model="passwordConfirm"
            ref="confirmation"
            v-validate="password ? 'required|confirmed:password' : ''"
            type="password"
            name="passwordConfirmation"
            :data-vv-as="$t('login.passwordConfirm')"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('passwordConfirmation')"
            >{{ errors.first("passwordConfirmation") }}</span
          >
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-8 flex flex-wrap items-center justify-end">
            <vs-button
              id="button-with-loading"
              class="ml-auto mt-2 vs-con-loading__container"
              @click="saveChanges"
              :disabled="!validateForm || $store.state.AppActiveUser.type === 0"
              >{{ $t("actions.save") }}</vs-button
            >
            <vs-button
              class="ml-4 mt-2"
              type="flat"
              color="dark"
              @click.native="$router.push('/users').catch(() => {})"
              >{{ $t("actions.cancel") }}</vs-button
            >
          </div>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import i18n from "@/i18n/i18n";
import vSelect from "vue-select";

export default {
  components: {
    vSelect
  },
  data: () => ({
    data_local: null,
    companyOptions: [],
    password: null,
    passwordConfirm: null,
    roleOptions: [
      { label: i18n.t("permission.user"), value: 0 },
      { label: i18n.t("permission.admin"), value: 1 }
    ]
  }),
  computed: {
    validateForm() {
      return !this.errors.any();
    }
  },
  beforeMount() {
    if (!this.$route.params.new && !this.$route.params.user) {
      this.$router.push("/users");
    }
  },
  created() {
    this.data_local = this.$route.params.user;
    this.loadData();
  },
  methods: {
    loadData() {
      var self = this;

      this.$store
        .dispatch("httpRequest", { method: "get", url: "company/all" })
        .then(data => {
          self.companyOptions = data.filter(x => x.active);
        })
        .catch(error => {
          this.$vs.notify({
            title: i18n.t("dialog.error"),
            text: error.message,
            color: "danger"
          });
        });
    },
    saveChanges() {
      this.$validator.validateAll().then(result => {
        if (result) {
          var self = this;

          this.$vs.loading({
            container: "#button-with-loading",
            scale: 0.5
          });

          if (this.password) {
            this.data_local.password = this.password;
          }

          if (this.data_local.company_name) {
            delete this.data_local.company_name;
          }

          var isNew = this.$route.params.new;
          var url = isNew ? "user/create" : "user/edit";
          var message = isNew
            ? i18n.t("user.messages.success.create")
            : i18n.t("user.messages.success.edit");

          this.$store
            .dispatch("httpRequest", {
              method: "post",
              url: url,
              data: this.data_local
            })
            .then(data => {
              if (self.data_local.id == self.$store.state.AppActiveUser.id) {
                self.$store.commit("UPDATE_USER_INFO", {
                  name: self.data_local.name
                });

                if (data.token) {
                  localStorage.setItem("accessToken", data.token);
                }
              }

              self.$vs.loading.close("#button-with-loading > .con-vs-loading");
              this.$router.push("/users");

              this.$vs.notify({
                title: i18n.t("dialog.success"),
                text: message,
                color: "success"
              });
            })
            .catch(error => {
              self.$vs.loading.close("#button-with-loading > .con-vs-loading");
              this.$vs.notify({
                title: i18n.t("dialog.error"),
                text: error.message,
                color: "danger"
              });
            });
        }
      });
    }
  }
};
</script>
